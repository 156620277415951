import { MicroFrontends } from 'src/models/microFrontends';
import { ResumeCardData } from 'src/models/resumeCard';
import fetcher from 'src/utils/fetcher';

const GET_RESUME_URL = '/shards/resume';

interface GetResumeParams {
    resumeId: string;
    resumeHash: string;
    topicId?: string;
    vacancyId?: string;
    getWebcallMicrofrontend?: boolean;
}

declare global {
    interface FetcherGetApi {
        [GET_RESUME_URL]: {
            queryParams: GetResumeParams;
            response: {
                resume: ResumeCardData;
                microFrontends: MicroFrontends;
            };
        };
    }
}

const getResume = (params: GetResumeParams, signal?: AbortSignal) => fetcher.get(GET_RESUME_URL, { params, signal });

export default getResume;
